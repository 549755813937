import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import routes from "./routes";
import { setUserIP, setUserType } from "./actions";

import "../assets/main.scss";

const App = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!sessionStorage.getItem("IP")) {
      dispatch(setUserIP(new URLSearchParams(window.location.search).get("ip")));
      dispatch(setUserType(new URLSearchParams(window.location.search).get("type")));
    }
  }, []);

  return (
    <main className="content-wrapper">
      <header className="app-header">
        <h1 className="app-header__title">{t("t:common.bankid-e-legitimation")}</h1>
      </header>
      {routes}
    </main>
  );
};

export default App;
