import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Grow from "@material-ui/core/Grow";

import LogoWrapper from "../../Shared/LogoWrapper";
import urls from "../../core/urls";
import {
  getAnimatedQRRequest,
  getCancelRequest,
  getCollectRequest,
  postAuthAuthostart,
  resetStore
} from "../../core/actions";
import { errorOccurred, onSuccessRedirect, transcriptCollectStatus } from "../../core/helpers";

import "./styles.scss";
import SkeletonComponent from "../../Shared/Skeleton/Skeleton";

const BankIDWithQRCode = () => {
  const [collectInterval, setCollectInterval] = useState(null);
  // const [QRFetchingInterval, setQRFetchingInterval] = useState(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { IP, type, autoStartToken, qrCode, collectErrorCode, collectStatus } = useSelector(({ app }) => app);

  const onUnmount = () => {
    dispatch(resetStore());
    if (collectInterval) clearInterval(collectInterval);
    // clearInterval(QRFetchingInterval);
  };

  const fetchAutostartToken = () => {
    dispatch(postAuthAuthostart({ IP }));
  };

  useEffect(() => {
    fetchAutostartToken();
    return () => onUnmount();
  }, []);

  const handleQRFetchingInterval = async () => {
    await dispatch(getAnimatedQRRequest(autoStartToken));
    return null;
    // setQRFetchingInterval(     // Actually not the interval but a single request now because
    //   setInterval(() => {      // the animated QR not working on the BE side currently
    //     dispatch(getAnimatedQRRequest(autoStartToken));
    //   }, 1000)
    // );
  };

  const handleCollectInterval = () => {
    setCollectInterval(
      setInterval(() => {
        dispatch(getCollectRequest(autoStartToken));
      }, 2000)
    );
  };

  useEffect(() => {
    if (autoStartToken) {
      handleQRFetchingInterval().then(() => handleCollectInterval());
    }
    if (collectInterval) clearInterval(collectInterval);
  }, [autoStartToken]);

  useEffect(() => {
    if (errorOccurred(collectErrorCode) || collectStatus?.toLowerCase() === "complete") {
      if (collectInterval) clearInterval(collectInterval);
      if (collectStatus?.toLowerCase() === "complete") onSuccessRedirect(autoStartToken, type);
    }
  }, [collectErrorCode, collectStatus]);

  const onCancelClick = async () => {
    dispatch(getCancelRequest(autoStartToken));
    onUnmount();
    history.push(urls.chooseMethod.path);
  };

  return (
    <Grow in>
      <div className="bank-id-with-qr-code">
        <LogoWrapper text={t("t:common.bankid-with-qr-code")} />
        {errorOccurred(collectErrorCode) ? (
          <section className="bank-id-with-qr-code__wrapper">
            <div className="bank-id-with-qr-code__status-text">
              {transcriptCollectStatus(collectErrorCode, t, "bankIDOnAnotherDevice")}
            </div>
            <div className="bank-id-with-qr-code__buttons">
              <button type="button" onClick={onCancelClick} className="cancel-btn">
                {t("t:common.cancel")}
              </button>
            </div>
          </section>
        ) : (
          <section className="bank-id-with-qr-code__wrapper">
            <p className="bank-id-with-qr-code__text">{t("t:common.launch-bankid-app-and-read-qr-code")}</p>
            <SkeletonComponent loading={!qrCode} variant="rectangle" width={128} height={128} classes="qr-code">
              <img src={`data:image/png;base64,${qrCode}`} alt="qr code" className="bank-id-with-qr-code__qr-code" />
            </SkeletonComponent>
            <button onClick={onCancelClick} className="cancel-btn">
              {t("t:common.cancel")}
            </button>
          </section>
        )}
      </div>
    </Grow>
  );
};

export default BankIDWithQRCode;
