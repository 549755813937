import React from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputMask from "react-input-mask";

import TooltipMessage from "../TooltipMessage/TooltipMessage";

import "./InputField.scss";

const propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  multiline: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  endAdornment: PropTypes.node
};

const InputField = ({
  id,
  type,
  label,
  autoFocus,
  disabled = false,
  multiline,
  error,
  endAdornment = false,
  ...props
}) => (
  <InputMask mask="999999-9999" {...props} disabled={disabled} type={type}>
    {inputProps => (
      <TextField
        {...inputProps}
        id={id}
        label={label}
        variant="filled"
        error={!!error}
        autoComplete="off"
        autoFocus={autoFocus}
        multiline={multiline}
        rows="4"
        rowsMax="10"
        classes={{
          root: "input-field"
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {error ? (
                <TooltipMessage text={error} delay={200} error>
                  <div className="error-icon" />
                </TooltipMessage>
              ) : endAdornment ? (
                endAdornment
              ) : (
                ""
              )}
            </InputAdornment>
          ),
          classes: {
            root: "custom-input",
            input: "custom-input__input",
            focused: "custom-input__focused",
            error: "custom-input__error",
            adornedEnd: "custom-input__adorned",
            multiline: "custom-input__multiline",
            disabled: "custom-input__disabled"
          }
        }}
        InputLabelProps={{
          classes: {
            root: "input-label",
            focused: "input-label__focused",
            shrink: "input-label__active",
            error: "input-label__error",
            disabled: "input-label__disabled"
          }
        }}
      />
    )}
  </InputMask>
);

InputField.propTypes = propTypes;

export default InputField;
