import React from "react";
import PropTypes from "prop-types";
import Tooltip from "@material-ui/core/Tooltip";

import "./TooltipMessage.scss";

const propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  children: PropTypes.node.isRequired,
  error: PropTypes.bool,
  delay: PropTypes.number,
  position: PropTypes.string,
  classes: PropTypes.string,
  interactive: PropTypes.bool
};

const TooltipMessage = ({
  text,
  children,
  error = false,
  delay = 1000,
  position = "top",
  classes = "tooltip_popper",
  interactive = false
}) => {
  return (
    <Tooltip
      title={text}
      placement={position}
      enterDelay={delay}
      leaveDelay={200}
      disableTouchListener
      disableFocusListener
      interactive={interactive}
      classes={{
        tooltip: error ? "error_message_tooltip" : "message_tooltip",
        popper: `message_popper ${classes}`
      }}
    >
      {children}
    </Tooltip>
  );
};

TooltipMessage.propTypes = propTypes;

export default TooltipMessage;
