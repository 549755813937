import urls from "../../core/urls";

export const identificationMethods = [
  {
    methodKey: "bankid-on-another-device",
    iconClassName: "bank-id-icon__width-38",
    url: urls.bankIDOnAnotherDevice.path
  },
  { methodKey: "bankid-on-this-device", iconClassName: "bank-id-icon__width-38", url: urls.bankIDOnThisDevice.path },
  { methodKey: "bankid-with-qr-code", iconClassName: "qr-code-icon", url: urls.bankIDWithQRCode.path }
];
