import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { createBrowserHistory } from "history";
import { routerMiddleware, ConnectedRouter } from "connected-react-router";
import { multiClientMiddleware } from "redux-axios-middleware";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";

import api from "./core/api";
import rootReducer from "./core/rootReducer";
import rootSaga from "./core/rootSaga";
import App from "./core/container";

import common_sv from "./core/translations/sv/common.json";
// import common_en from "./core/translations/en/common.json";

const axiosMiddlewareOptions = {
  interceptors: {
    request: [
      (action, config) => {
        config.headers["Ocp-Apim-Subscription-Key"] = process.env.REACT_APP_SUBSCRIPTION_KEY;
        return config;
      }
    ],
    response: [
      {
        success: (func, response) => {
          return response;
        },
        error: (func, error) => {
          if (error.response.status === 503) {
            alert("Internt tekniskt fel. Försök igen.");
          }
          return Promise.reject(error);
        }
      }
    ]
  }
};

i18next.init({
  interpolation: { escapeValue: false },
  lng: "sv",
  resources: {
    sv: {
      t: common_sv
    }
  },
  transSupportBasicHtmlNodes: true,
  transKeepBasicHtmlNodesFor: ["br", "strong", "i"],
  saveMissing: true
});

const history = createBrowserHistory();
const appRouterMiddleware = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();
const createStoreWithMiddleware = applyMiddleware(
  multiClientMiddleware(api, axiosMiddlewareOptions),
  appRouterMiddleware,
  sagaMiddleware
)(createStore);
const store = createStoreWithMiddleware(
  rootReducer(history),
  {},
  window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
);

sagaMiddleware.run(rootSaga);

ReactDOM.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18next}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </I18nextProvider>
  </Provider>,
  document.getElementById("wrapper")
);
