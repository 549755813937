import React from "react";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import PropTypes from "prop-types";

import "./DefaultButton.scss";

const propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.string,
  type: PropTypes.string,
  to: PropTypes.string,
  formAction: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  classes: PropTypes.string
};

const defaultProps = {
  variant: "contained",
  type: "button",
  to: "",
  formAction: false,
  disabled: false,
  onClick: () => {},
  loading: false,
  classes: ""
};

const DefaultButton = ({ children, variant, type, to, formAction, disabled, onClick, loading, classes }) => {
  if (type === "link") {
    return (
      <div className="default_button_wrapper">
        <Button
          component={Link}
          to={to}
          variant={variant}
          classes={{
            root: `default_button default_button_${variant} ${classes}`
          }}
        >
          {children}
        </Button>
      </div>
    );
  } else {
    return (
      <div className="default_button_wrapper">
        <Button
          type={formAction ? "submit" : "button"}
          variant={variant}
          disabled={disabled}
          onClick={onClick}
          classes={{
            root: `default_button default_button_${variant} ${classes}`
          }}
        >
          {loading ? <CircularProgress size={24} color="inherit" /> : children}
        </Button>
      </div>
    );
  }
};

DefaultButton.propTypes = propTypes;
DefaultButton.defaultProps = defaultProps;

export default DefaultButton;
