import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Grow from "@material-ui/core/Grow";

import { identificationMethods } from "./constants";
import IconButton from "../../Shared/IconButton/IconButton";
import LogoWrapper from "../../Shared/LogoWrapper";

import "./styles.scss";

const ChooseIdentificationMethod = () => {
  const { t } = useTranslation();
  return (
    <Grow in>
      <div className="choose-identification-method">
        <section className="choose-identification-method__methods">
          {identificationMethods.map(({ methodKey, iconClassName, url }) => (
            <Link to={url} className="choose-identification-method__method" key={methodKey}>
              <div className="choose-identification-method__method--info">
                <div className={iconClassName} />
                <div className="method-name">{t(`t:common.${methodKey}`)}</div>
              </div>
              <IconButton>
                <div className="choose-identification-method__method--link arrow-right-icon__black" />
              </IconButton>
            </Link>
          ))}
        </section>
        <LogoWrapper text={t("t:common.choose-identification-method")} />
      </div>
    </Grow>
  );
};

export default ChooseIdentificationMethod;
