import {
  BUTTON_LOADING,
  SET_USER_IP,
  POST_AUTH_SUCCESS,
  POST_AUTH_AUTHOSTART_SUCCESS,
  GET_ANIMATED_QR_SUCCESS,
  RESET_STORE,
  RESET_COLLECT_STATUS,
  GET_COLLECT_SUCCESS,
  GET_COLLECT_FAIL,
  SET_USER_TYPE
} from "./actionTypes";

const INITIAL_STATE = {
  buttonLoading: false,
  IP: sessionStorage.getItem("IP"),
  type: sessionStorage.getItem("type"),
  autoStartToken: null,
  qrCode: null,
  collectErrorCode: null,
  collectStatus: null
};

export default function(state = INITIAL_STATE, { type, payload, IP, buttonLoading, urlType }) {
  switch (type) {
    case RESET_STORE:
      return {
        ...INITIAL_STATE,
        IP: state.IP || sessionStorage.getItem("IP"),
        type: state.type || sessionStorage.getItem("type")
      };
    case RESET_COLLECT_STATUS:
      return { ...state, collectErrorCode: null, collectStatus: null };
    case BUTTON_LOADING:
      return { ...state, buttonLoading };
    case SET_USER_IP:
      sessionStorage.setItem("IP", IP);
      return { ...state, IP };
    case SET_USER_TYPE:
      sessionStorage.setItem("type", urlType);
      return { ...state, type: urlType };
    case POST_AUTH_SUCCESS:
      return { ...state, autoStartToken: payload.data.autoStartToken };
    case POST_AUTH_AUTHOSTART_SUCCESS:
      return { ...state, autoStartToken: payload.data.autoStartToken };
    case GET_ANIMATED_QR_SUCCESS:
      return { ...state, qrCode: payload.data.qr };
    case GET_COLLECT_SUCCESS:
      return {
        ...state,
        collectErrorCode: payload.data.message || "unknownError",
        collectStatus: payload.data.status || payload.data.Status,
        autoStartToken: payload.data.AutoStartToken || payload.data.autoStartToken || state.autoStartToken
      };
    case GET_COLLECT_FAIL:
      return {
        ...state,
        collectErrorCode: "internalError",
        collectStatus: "failed"
      };
    default:
      return state;
  }
}
