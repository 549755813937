export const BUTTON_LOADING = "BUTTON_LOADING";

export const SET_USER_IP = "SET_USER_IP";
export const SET_USER_TYPE = "SET_USER_TYPE";
export const RESET_STORE = "RESET_STORE";
export const RESET_COLLECT_STATUS = "RESET_COLLECT_STATUS";

export const POST_AUTH = "POST_AUTH";
export const POST_AUTH_SUCCESS = "POST_AUTH_SUCCESS";
export const POST_AUTH_FAIL = "POST_AUTH_FAIL";

export const POST_AUTH_AUTHOSTART = "POST_AUTH_AUTHOSTART";
export const POST_AUTH_AUTHOSTART_SUCCESS = "POST_AUTH_AUTHOSTART_SUCCESS";
export const POST_AUTH_AUTHOSTART_FAIL = "POST_AUTH_AUTHOSTART_FAIL";

export const GET_ANIMATED_QR = "GET_ANIMATED_QR";
export const GET_ANIMATED_QR_SUCCESS = "GET_ANIMATED_QR_SUCCESS";
export const GET_ANIMATED_QR_FAIL = "GET_ANIMATED_QR_FAIL";

export const GET_CANCEL = "GET_CANCEL";
export const GET_CANCEL_SUCCESS = "GET_CANCEL_SUCCESS";
export const GET_CANCEL_FAIL = "GET_CANCEL_FAIL";

export const GET_COLLECT = "GET_COLLECT";
export const GET_COLLECT_SUCCESS = "GET_COLLECT_SUCCESS";
export const GET_COLLECT_FAIL = "GET_COLLECT_FAIL";
