import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import urls from "./urls";

import ChooseIdentificationMethod from "../Features/ChooseIdentificationMethod";
import BankIDOnAnotherDevice from "../Features/BankIDOnAnotherDevice";
import BankIDOnThisDevice from "../Features/BankIDOnThisDevice";
import BankIDWithQRCode from "../Features/BankIDWithQRCode";

export default (
  <Switch>
    <Route path={urls.chooseMethod.path} component={ChooseIdentificationMethod} />
    <Route path={urls.bankIDOnAnotherDevice.path} component={BankIDOnAnotherDevice} />
    <Route path={urls.bankIDOnThisDevice.path} component={BankIDOnThisDevice} />
    <Route path={urls.bankIDWithQRCode.path} component={BankIDWithQRCode} />
    <Route render={() => <Redirect to={urls.chooseMethod.path} />} />
  </Switch>
);
