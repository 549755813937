/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import _ from "lodash";
import Grow from "@material-ui/core/Grow";

import LogoWrapper from "../../Shared/LogoWrapper";
import InputField from "../../Shared/InputField/InputField";
import DefaultButton from "../../Shared/DefaultButton/DefaultButton";
import urls from "../../core/urls";
import { socialNumberValidation } from "../../core/validations";
import { getCancelRequest, getCollectRequest, postAuth, resetStore, resetCollectStatus } from "../../core/actions";
import Loader from "../../Shared/Loader";
import { errorOccurred, onSuccessRedirect, transcriptCollectStatus } from "../../core/helpers";

import "./styles.scss";

const BankIDOnAnotherDevice = () => {
  const [identificationStarted, setIdentificationStarted] = useState(false);
  const [collectInterval, setCollectInterval] = useState(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { IP, type, buttonLoading, autoStartToken, collectErrorCode, collectStatus } = useSelector(({ app }) => app);

  const onUnmount = () => {
    dispatch(resetStore());
    if (collectInterval) clearInterval(collectInterval);
  };

  useEffect(() => () => onUnmount(), []);

  const schema = useMemo(
    () =>
      yup.object().shape({
        SSN: socialNumberValidation(t)
      }),
    []
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      SSN: ""
    },
    shouldFocusError: true
  });

  const handleCollectInterval = () => {
    setCollectInterval(
      setInterval(() => {
        dispatch(getCollectRequest(autoStartToken));
      }, 2000)
    );
  };

  useEffect(() => {
    if (autoStartToken) {
      if (collectInterval) clearInterval(collectInterval);
      handleCollectInterval();
    }
  }, [autoStartToken]);

  useEffect(() => {
    if (errorOccurred(collectErrorCode) || collectStatus?.toLowerCase() === "complete") {
      if (collectInterval) clearInterval(collectInterval);
      if (collectStatus?.toLowerCase() === "complete") onSuccessRedirect(autoStartToken, type);
    }
  }, [collectErrorCode, collectStatus]);

  const handleIdentificationStart = () => {
    setIdentificationStarted(!identificationStarted);
  };

  const prepareDataForSubmit = data => ({
    ...data,
    IP
  });

  const onSubmit = async data => {
    const res = await dispatch(postAuth(prepareDataForSubmit(data)));
    if (!res || res.error) {
      setError("SSN", {
        type: "manual",
        message: res.error?.response?.data?.error || t("t:user-messages.internalError")
      });
    } else {
      handleIdentificationStart();
    }
  };

  const onCancelClick = () => {
    dispatch(getCancelRequest(autoStartToken));
    onUnmount();
    handleIdentificationStart();
  };

  const onRestartClick = () => {
    dispatch(resetCollectStatus());
    handleCollectInterval();
  };

  return (
    <Grow in>
      <div className="bank-id-on-another-device">
        <LogoWrapper text={t("t:common.bankid-on-another-device")} />
        {identificationStarted ? (
          <section className="bank-id-on-another-device__wrapper">
            <div className="bank-id-on-another-device__status-text">
              {transcriptCollectStatus(collectErrorCode, t, "bankIDOnAnotherDevice")}
            </div>
            {!errorOccurred(collectErrorCode) && <Loader />}
            <div className="bank-id-on-another-device__buttons">
              {errorOccurred(collectErrorCode) && (
                <DefaultButton variant="contained" onClick={onRestartClick}>
                  {t("t:common.restart")}
                </DefaultButton>
              )}
              {collectStatus !== "Ok" && (
                <button type="button" onClick={onCancelClick} className="cancel-btn">
                  {t("t:common.cancel")}
                </button>
              )}
            </div>
          </section>
        ) : (
          <form className="bank-id-on-another-device__wrapper" onSubmit={handleSubmit(onSubmit)}>
            <p className="bank-id-on-another-device__text">{t("t:common.launch-bankid-app-on-your-phone")}</p>
            <p className="bank-id-on-another-device__text">{t("t:common.enter-your-social-security-number")}</p>
            <Controller
              name="SSN"
              control={control}
              render={({ field, ref }) => (
                <InputField
                  id="name-input"
                  label={t("t:common.social-number-placeholder")}
                  type="text"
                  {..._.omit(field, "ref")}
                  inputRef={ref}
                  error={errors.SSN?.message}
                />
              )}
            />
            <DefaultButton variant="contained" formAction loading={buttonLoading}>
              {t("t:common.start-identification")}
            </DefaultButton>
            <Link to={urls.chooseMethod.path} className="cancel-btn">
              {t("t:common.cancel")}
            </Link>
          </form>
        )}
      </div>
    </Grow>
  );
};

export default BankIDOnAnotherDevice;
