import React from "react";
import PropTypes from "prop-types";

import "./styles.scss";

const propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired
};

const LogoWrapper = ({ text }) => (
  <section className="logo-wrapper">
    <div className="bank-id-icon__width-100" />
    <div className="bank-id-icon__width-64" />
    <div className="logo-wrapper--title">{text}</div>
  </section>
);

LogoWrapper.propTypes = propTypes;

export default LogoWrapper;
