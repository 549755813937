import React from "react";
import IconButton from "@material-ui/core/IconButton";
import PropTypes from "prop-types";

import "./IconButton.scss";

const propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.string,
  onClick: PropTypes.func
};

const defaultProps = {
  classes: "",
  onClick: () => {}
};

const IconButtonComponent = ({ children, classes, onClick }) => (
  <IconButton
    onClick={onClick}
    classes={{
      root: classes
    }}
  >
    {children}
  </IconButton>
);

IconButtonComponent.propTypes = propTypes;
IconButtonComponent.defaultProps = defaultProps;

export default IconButtonComponent;
