import { put, takeEvery, all } from "redux-saga/effects";

export default function* rootSaga() {
  yield all([handleLoader()]);
}

export function* openErrorSnack(e) {
  yield put({
    type: "ERROR_SNACK_OPEN",
    payload:
      e && e.error && e.error.response && e.error.response.data
        ? typeof Object?.values(e.error.response.data)[0] === "string"
          ? Object?.values(e.error.response.data)[0]
          : Object?.values(e.error.response.data)[0][0]
        : typeof e.error === "string"
        ? e.error
        : "Something went wrong"
  });
}

export function* enableButtonLoader() {
  yield put({ type: "BUTTON_LOADING", buttonLoading: true });
}

export function* disableButtonLoader() {
  yield put({ type: "BUTTON_LOADING", buttonLoading: false });
}

function* handleLoader() {
  yield takeEvery("POST_AUTH", enableButtonLoader);
  yield takeEvery("POST_AUTH_SUCCESS", disableButtonLoader);
  yield takeEvery("POST_AUTH_FAIL", disableButtonLoader);

  yield takeEvery("POST_AUTH_AUTHOSTART", enableButtonLoader);
  yield takeEvery("POST_AUTH_AUTHOSTART_SUCCESS", disableButtonLoader);
  yield takeEvery("POST_AUTH_AUTHOSTART_FAIL", disableButtonLoader);
}
