import React from "react";

import "./styles.scss";

const Loader = () => (
  <div className="loading">
    <div className="dot" />
    <div className="dot" />
    <div className="dot" />
    <div className="dot" />
    <div className="dot" />
  </div>
);

export default Loader;
